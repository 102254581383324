type ExtractNewsPublisherProps = {
	categories: string[]
}


const extractNewsAuthor = (props: ExtractNewsPublisherProps) => {
	const {categories} = props
	return categories.find(category => category.includes('author'))?.split('-').slice(1).join(' ') as string
}

export {extractNewsAuthor}
