import {PublicationSources} from "../../../utils/app";

type ExtractNewsPublisherProps = {
	categories: string[]
}


const extractNewsPublisher = (props: ExtractNewsPublisherProps) => {
	const {categories} = props
	const initialPublisherPresetValues = {'station-locate': false, 'new-york-times': false, 'la-times': false}
	
	const publishersPresent = new Map(Object.entries(initialPublisherPresetValues).map(([key, value]) => [key, value]))
	if (categories.includes('station-locate')) {
		publishersPresent.set('station-locate', true)
	}
	if (categories.includes('new-york-times')) {
		publishersPresent.set('new-york-times', true)
	}
	if (categories.includes('la-times')) {
		publishersPresent.set('la-times', true)
	}
	if (publishersPresent.get('station-locate')) {
		return 'station-locate' as PublicationSources
	}
	if (publishersPresent.get('new-york-times')) {
		return 'new-york-times' as PublicationSources
	}
	if (publishersPresent.get('la-times')) {
		return 'la-times' as PublicationSources
	}
	return 'station-locate' as PublicationSources
}

export {extractNewsPublisher}
