import React from "react";
import GreenSUV from '../Images/SUV4.png'
import GreenSuccessIcon from '../Images/GreenSuccessIcon.png'
import {StationLocateAppDownloadContainer, SubmitSubscribeToNewsletterGoogleForm} from "../pageComponents/home/";

type HomeBodyProps = {
  children?: React.ReactNode;
};

const Home: React.FunctionComponent<HomeBodyProps> = ({children}: HomeBodyProps) => {
  const listContainer = 'flex  flex-row mt-4 px-0.5 '
  
  return (
	  <div style={{
		backgroundImage: `url(${GreenSUV})`, backgroundRepeat: 'repeat-x', width: '100vw',
	  }}>
		<main>
		  {children}
		  <div
			  className="  min-h-[100vh] max-h-[1000vh]  md:px-6 lg:px-8 mx-8 ">
			<div className=" px-2 md:px-4 ">
			  <div className="    md:w-full  "
			  >
				<div className="pt-[10vh]">
				  <p className=" text-2xl md:text-6xl md:pt-4 ml-4 md:ml-10 antialiased font-semibold text-green-900">
					GOAL: Sustainable Transportation.
				  </p>
				
				</div>
				<div>
				  <p className="md:text-3xl py-2 pr-2 md:pt-11 ml-4 md:ml-10 antialiased font-semibold text-slate-600">
					At BackHome Technologies, we believe that sustainable transportation is the key to a better future.
					We are committed to developing and implementing innovative solutions to power transportation systems
					and
					infrastructure that will reduce our reliance on fossil fuels, improve air quality, and create a more
					livable planet.</p>
				  <p className="md:text-3xl py-2 pr-2 md:pt-4 ml-4 md:ml-10 antialiased font-semibold text-slate-600">
					We are passionate about making a
					difference in the world, and we believe that sustainable transportation is one of the most important
					ways to do that. We are committed to working with our clients to create a more sustainable future
					for all. We are committed to working with our
					clients to create a more sustainable future for all.</p>
				  <p className="md:text-3xl  pr-2 md:pt-4 ml-4 md:ml-10 antialiased font-semibold text-slate-600">
					With focus in the transportation industry, some of our approach include:
				  </p>
				  
				  <div className="ml-2 md:ml-10 mr-1">
					<ul className=" md:text-2xl py-2 pr-2  ml-4 md:ml-10 antialiased font-semibold text-slate-600 ">
					  <li className={`${listContainer}   `}>
						<img className="h-5 w-5 mr-2 mt-3 " src={GreenSuccessIcon} alt="bullet-pointt"/>
						Creating software solutions aimed at solving the issue of common barriers encountered during ICE
						to EV transitions, e.g. range anxiety. We have launched apps on App Store and Google Play to
						help EV owners, locate and navigate to suitable charging stations.
					  </li>
					  <li className={`${listContainer} items-center  `}>
						<img className="h-5 w-5 mr-2 mt-3" src={GreenSuccessIcon} alt="bullet-point"/>
						We are researching and developing equipments to improve EV adoption rates in North America.
					  </li>
					
					</ul>
				  </div>
				
				</div>
			  </div>
			  
			  
			  <div className='flex justify-center flex-col '>
				<div className='flex justify-center lg:mt-[1vh] md:mt-[70vh]'>
				  <p className="text-2xl md:text-6xl lg:pt-10 md:pt-8 ml-4 md:ml-10 antialiased font-semibold text-green-900">
					Explore our solutions
				  </p>
				
				</div>
				<div>
				  <p className="md:text-3xl py-2 pr-2 md:pt-11 ml-4 md:ml-10 antialiased font-semibold text-slate-600">
					Our app makes it easy to find the nearest charging station no matter where you are.
					With features like map directions, and user reviews, you can be sure
					you'll always find a reliable charging station when you need it. </p>
				
				</div>
				<StationLocateAppDownloadContainer/>
			  </div>
			  
			  <div className="flex items-center justify-center flex-col  mb-10  mt-[5vh] md:mt-[10vh]">
				<SubmitSubscribeToNewsletterGoogleForm/>
			  
			  </div>
			</div>
		  
		  
		  </div>
		</main>
	  </div>
  );
};
export default Home;
