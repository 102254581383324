import {ReactNode, useEffect, useState} from "react";
import {NewsCard} from "../pageComponents/news";
import {transformNewsFeed} from "../pageComponents/news/helpers";
import {NewsItemType} from "../utils/app";

type InTheNewsProps = {
  children?: ReactNode;
};

const InTheNews = (x: InTheNewsProps) => {
  const {children} = x;
  const [mediumNews, setMediumNews] = useState({
	
	newsItems: [] as NewsItemType[],
	isLoading: true,
	error: null
  });
  
  const {newsItems, isLoading} = mediumNews;
  
  useEffect(() => {
	fetch('https://us-central1-stationlocate-prod.cloudfunctions.net/slnev1?newsArticles').then(async res => {
		  const newsArticles = await res.json();
		  const transformedNewsArticles = transformNewsFeed({newsFeed: newsArticles});
		  setMediumNews(e => ({...e, newsItems: transformedNewsArticles, isLoading: false}));
		}
	).catch(err => {
	  setMediumNews(e => ({
		...e,
		error: err.message
	  }));
	});
  }, [])
  
  const hasNews = () => {
	if (!!newsItems.length) {
	  return <div className="grid gap-2 md:gap-3 grid-cols-2 md:grid-cols-3 ">
		{newsItems.map((newsItem: any) => <NewsCard key={newsItem.id} newsItem={newsItem}/>)}
	  </div>
	} else {
	  return <div className="grid gap-2 md:gap-3 grid-cols-2 md:grid-cols-3 ">
		<div className="col-span-2">
		  <div className="text-center">
			<p className="text-gray-500 text-sm">
			  No news articles for now
			</p>
		  </div>
		</div>
	  </div>
	}
  }
  
  return <div>
	<main>
	  {children}
	  <div className="max-w-7xl mx-auto py-6  min-h-[109vh] max-h-[4000vh]  sm:px-6 lg:px-8">
		<div className="px-4 py-6 sm:px-0">
		  <div className="px-4 sm:px-0">
			<h3 className="flex justify-center text-green-900 tracking-widest font-sans antialiased  text-4xl font-extrabold md:mb-8">Welcome
			  To Station
			  Locate
			  Blogs</h3>
			
			{isLoading ? <p>Loading...</p> : hasNews()}
		  
		  
		  </div>
		</div>
	  </div>
	</main>
  </div>
};

export {InTheNews};
