import React, { useEffect } from "react";
export default function Disclaimer() {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://app.termly.io/embed-policy.min.js";
		script.async = true;
		document.body.appendChild(script)
	}, []);
	
	return (
		<div
			name="termly-embed"
			data-id="06eb7fe2-9f20-4e3c-8745-f5a36235514d"
			datatype="iframe"
		></div>
	);
}
