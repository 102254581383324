import React, {useEffect} from "react";

export default function Policy() {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://app.termly.io/embed-policy.min.js";
		script.async = true;
		document.body.appendChild(script)
		
	}, []);
	
	
	return (
		<div
			name="termly-embed"
			data-id="fa46696c-d4a8-4717-833b-70a130735bda"
			datatype="iframe"
		></div>
	);
}
