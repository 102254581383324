import React from "react";

type HeaderTextProps = {
  headerText: string;
};

const HeaderText: React.FunctionComponent<HeaderTextProps> = ({
  headerText,
}: HeaderTextProps) => {
  return (
    <h1 className="text-base md:text-3xl font-bold text-gray-900">
      {headerText}
    </h1>
  );
};

export { HeaderText };
