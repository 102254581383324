import {ContactUsContainer, MissionCard} from "../pageComponents/about";

const About = () => {
  
  return (
	  <div>
		<main>
		  <div className=" ml-2 mr-1  lg:mx-20   pb-20  min-h-[109vh] lg:px-6  md:px-1">
			<div className=" px-2 lg:px-32   md:px-20  sm:px-0">
			  <h1 className="flex py-6 md:py-8 justify-center text-green-900  text-2xl  font-semibold md:text-5xl">
				About BackHome Technologies</h1>
			  <MissionCard/>
			  
			  <ContactUsContainer/>
			</div>
		  </div>
		</main>
	  </div>
  );
};

export {About};
