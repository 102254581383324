import {useEffect, useState} from "react";
import {PressReleaseCard} from "../pageComponents/news";
import {BlogArticleType} from "../utils/app";

type NewsBodyProps = {
  children?: React.ReactNode;
};

const StationLocatePressReleases: React.FunctionComponent<NewsBodyProps> = ({
																			  children
																			}: NewsBodyProps) => {
  
  const [mediumPressReleases, setMediumPressReleases] = useState({
	
	pressReleaseItems: [] as BlogArticleType[],
	isLoading: true,
	error: null
  });
  
  const {pressReleaseItems, isLoading} = mediumPressReleases;
  useEffect(() => {
	fetch('https://us-central1-stationlocate-prod.cloudfunctions.net/slnev1?pressReleases').then(async res => {
		  const pressReleaseArticles = await res.json();
		  setMediumPressReleases(e => ({...e, pressReleaseItems: pressReleaseArticles, isLoading: false}));
		}
	).catch(err => {
	  setMediumPressReleases(e => ({
		...e,
		error: err.message
	  }));
	});
  }, [])
  
  const hasPressRelease = () => {
	if (!!pressReleaseItems.length) {
	  return <div className="grid gap-2 md:gap-3 grid-cols-2 md:grid-cols-3 ">
		
		{pressReleaseItems.map((post: any, index: number) => <PressReleaseCard key={index}
																			   pressRelease={post}/>)}
	  </div>
	} else {
	  return <div className="grid gap-2 md:gap-3 grid-cols-2 md:grid-cols-3 ">
		<div className="col-span-2">
		  <div className="text-center">
			<p className="text-gray-500 text-sm">
			  No Press Release found
			</p>
		  </div>
		</div>
	  </div>
	}
  }
  return (
	  <div>
		<main>
		  {children}
		  <div className="max-w-7xl mx-auto py-6 min-h-[109vh]  sm:px-6 lg:px-8">
			<div className="px-4 py-6 sm:px-0">
			  <div className="px-4 sm:px-0">
				<h3 className="flex justify-center text-green-900 tracking-widest font-sans antialiased  text-4xl font-extrabold md:mb-8">Welcome
				  To Station
				  Locate
				  Press Releases</h3>
				{isLoading ? <p>Loading...</p> : hasPressRelease()}
			  </div>
			
			</div>
		  </div>
		</main>
	  </div>
  );
};

export {StationLocatePressReleases};
