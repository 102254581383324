import React from "react";
import {useIntl} from 'react-intl';
import {headerMessages} from "../../messages";

type LegalsAndPoliciesProps = {
  buttonClicks: (buttonClicked: string) => void;
};
const NewsAndBlog: React.FunctionComponent<LegalsAndPoliciesProps> = ({
																		buttonClicks
																	  }: LegalsAndPoliciesProps) => {
  const {formatMessage} = useIntl();
  return (
	  <div
		  className={"origin-bottom-left mt-[25px] md:mt-9 absolute -ml-5 md:ml-0  w-28   md:w-[150px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"}
		  role="menu"
		  aria-orientation="vertical"
		  aria-labelledby="news-menu-button"
		  tabIndex={-1}
	  >
		
		<button
			className=" pl-[0.2rem]   py-1 md:py-1  text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			type="button"
			onClick={() => {
			  buttonClicks('/in-the-news');
			}}
		>
		  {formatMessage(headerMessages.stationLocateNews)}
		</button>
		<button
			className=" pl-[0.2rem]   text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			type="button"
			onClick={() => {
			  buttonClicks('/press-releases');
			}}
		>
		  {formatMessage(headerMessages.stationLocatePressReleases)}
		</button>
		
		<button
			className=" pl-[0.2rem] py-1 text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			type="button"
			onClick={() => {
			  buttonClicks('/blog');
			}}
		>
		  {formatMessage(headerMessages.stationLocateBlog)}
		</button>
	  
	  </div>
  );
};

export {NewsAndBlog};
