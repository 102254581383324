import {displayStuff} from "../actionTypes";

const {DISPLAY_SET_LOCALE, DISPLAY_SET_SUBMITTED_NEWSLETTER_SUBSCRIPTION_FORM} = displayStuff

const initialState = {
  locale: 'en-US',
  submittedNewsletterSignup: false,
};

const displayReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
	case DISPLAY_SET_LOCALE:
	  return {
		...state,
		locale: action.payload,
	  };
	
	case DISPLAY_SET_SUBMITTED_NEWSLETTER_SUBSCRIPTION_FORM:
	  return {
		...state,
		submittedNewsletterSignup: action.payload,
	  };
	
	default:
	  return state;
  }
};

export {displayReducer};
