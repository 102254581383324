import React from "react";
import {SubmitContactUsGoogleForm} from "./SubmitContactUsGoogleForm";

const ContactUsContainer = () => {
  
  return (
	  <div className='flex flex-col justify-center items-center'>
		
		<h1
			className="flex text-lg md:text-3xl mb-2 md:mb-5 font-semibold text-green-900 items-center">
		  Contact Us</h1>
		<div className="flex flex-col md:flex-row  md:mt-8 md:mb-5  md:gap-[10vw]">
		  <div className=" md:w-2/5">
			<h2
				className="flex text-md md:text-2xl mb-2 md:mb-1 font-semibold text-green-900 items-center">
			  Mailing Address:
			</h2>
			<p className="text-slate-600  ">BackHome Technologies LLC</p>
			<p className="text-slate-600  ">90 Church Street</p>
			<p className="text-slate-600 ">Unit 3265</p>
			<p className="text-slate-600 ">New York, NY 10008</p>
			<div className="flex mt-2">
			  <p className="text-slate-600 font-semibold mr-2 ">Phone:</p>
			  <p className="text-slate-600 ">+1 347 220-3853</p>
			</div>
			<div className="flex items-center  justify-start mb-8 md:mb-1">
			  <p className="text-slate-600 font-semibold mr-2 ">Email:</p>
			  <a
				  href="mailto:support@backhometechnologies.com?body=Please%20type%20a%20email%20below"
				  rel="noopener noreferrer"
				  className="text-blue-700  "
			  >
				support@backhometechnologies.com
			  </a>
			</div>
		  
		  
		  </div>
		  <div><SubmitContactUsGoogleForm/></div>
		</div>
	  </div>
  )
}

export {ContactUsContainer}
