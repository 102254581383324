import React from "react";
import {BlogArticleType, titilliezNameOrPublisher} from "../../utils/app";

type BlogCardProps = {
	blogPost: BlogArticleType
}

const BlogCard = (x: BlogCardProps) => {
	const {blogPost} = x
	const {title, link, snippet, thumbnail, categories, publisher} = blogPost;
	return <div className="w-42 md:w-full drop-shadow-lg bg-gray-200 shadow-2xl rounded-2xl ">
		<div className="flex justify-center">
			<img className=" h-16 md:h-16 mt-1 md:mt-2  rounded-xl w-28 md:w-32" src={thumbnail} alt="Logo"/>
		</div>
		<div className="mx-3 mb-2 ">
			<div className="flex flex-col md:flex-row  py-1 md:py-3">
				
				<p className="text-gray-500 text-2xs md:text-xs">{titilliezNameOrPublisher({str: categories[(Math.floor(Math.random() * categories.length))]})}</p>
				<p className="md:ml-2 text-gray-500 text-2xs md:text-xs"><b>Author</b>: {publisher}</p>
			
			</div>
			<p className="text-xs md:text-sm font-serif pb-2 font-semibold text-gray-700">{title}</p>
			
			<p className=" text-sm md:text-base font-sans text-gray-900 line-clamp-5 ">{snippet}</p>
			
			<a href={link} target="_blank" rel="noreferrer" className="text-blue-400 font-semibold">Read
				more</a>
		</div>
	
	</div>
	
}

export {BlogCard}
