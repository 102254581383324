import {v4 as uuidv4} from 'uuid';
import {BlogArticleType, NewsItemType} from "../../../utils/app";
import {extractNewsAuthor} from "./extractNewsAuthor";
import {extractNewsCategory} from './extractNewsCategory';
import {extractNewsPublisher} from "./extractNewsPublisher";
import {getPublicationDate} from "./getPublicationDate";
import {publicationImageToUse} from "./publicationImageToUse";

type TransformNewsFeedProps = {
	newsFeed: BlogArticleType[];
}

const transformNewsFeed = (x: TransformNewsFeedProps) => {
	const {newsFeed} = x;
	return newsFeed.map(news => {
		const {categories, title, snippet, link, pubDate} = news
		const newsSource = extractNewsPublisher({categories})
		const publisherImage = publicationImageToUse({src: newsSource})
		const newsCategory = extractNewsCategory({categories})
		const authorName = extractNewsAuthor({categories})
		const date = getPublicationDate({publicationDate: pubDate})
		return {
			title,
			description: snippet,
			category: newsCategory,
			sourceUrl: link,
			publisherImage,
			date,
			id: uuidv4(),
			authorName,
			publisherName: newsSource
		} as NewsItemType
	})
}
export {transformNewsFeed};
