import {Button, Form, Input, notification, Radio, Space} from 'antd';
import React, {useCallback} from 'react';

const {TextArea} = Input;

const SubmitContactUsGoogleForm = () => {
  const [submitted, setSubmitted] = React.useState(false);
  
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  
  const onFinish = useCallback(
	  async ({email, feedbackText, fullName, phoneNumber, permissionToContact}: {
		email: string;
		feedbackText: string
		fullName: string;
		phoneNumber: string;
		permissionToContact: string;
	  }) => {
		try {
		  await fetch(
			  'https://docs.google.com/forms/d/e/1FAIpQLScqUwUqv-HCEm0dCBBPKayp8pAxcj_DnXF-2laQLscCUW4gsQ/formResponse?'
			  + new URLSearchParams({
				'entry.281140668': fullName,
				'entry.932973867': feedbackText,
				'entry.1407908677': permissionToContact,
				'entry.452797663': phoneNumber,
				emailAddress: email
			  }),
			  {
				mode: 'no-cors'
			  }
		  );
		  api.success({
			message: 'Submitted successfully'
		  })
		  setTimeout(() => {
			setSubmitted(true);
		  }, 2000)
		  
		} catch (e: any) {
		  api.error({
			message: e.message
		  });
		}
	  },
	  [api]
  );
  
  return (
	  <>
		{contextHolder}
		<h2
			className="flex text-md md:text-2xl mb-2 md:mb-1 font-semibold text-green-900 items-center">
		  Contact Form
		</h2>
		{submitted && <p className="text-gray-600 md:text-xl">Your feedback is especially helpful. We'll
			definitely take it into consideration as we
			continue to develop our products and services.
			Thank you again for your feedback. Someone will reach out to you if needed, and if you gave the
			permission for us to reach out to you.</p>}
		{!submitted && <>
			<p className="text-gray-800 mb-1 md:mb-1"><span className="text-amber-700">*</span> Indicates required
				field</p>
			<Form
				form={form}
				layout="vertical"
				wrapperCol={{span: 18}}
				onFinish={onFinish}
			>
				<Form.Item
					name="fullName"
					label="Full Name"
					className="font-semibold text-gray-800"
					rules={[{required: true, message: 'Name  is required'}]}
				>
					<Input placeholder={'eg. Obinna Nwafo'}/>
				</Form.Item>
				<Form.Item
					name="phoneNumber"
					label="Phone Number"
					className="font-semibold text-gray-800"
					rules={[
					  {required: true, message: 'Phone number  is required'}
					]}
				>
					<Input placeholder={'eg. 234-456-7890'}/>
				</Form.Item>
				<Form.Item
					name="email"
					label="Email"
					className="font-semibold text-gray-800"
					rules={[
					  {required: true, message: 'Email address is required',},
					  {
						type: 'email',
						message: 'The input is not valid E-mail!',
					  },
					]}
				>
					<Input placeholder={'eg. someone@example.com'}/>
				</Form.Item>
				
				<Form.Item
					name="permissionToContact"
					className="font-semibold text-gray-800"
					label="Can we contact you using information you provided?"
					rules={[{required: true, message: 'Your permission choice is required'}]}
				>
					<Radio.Group>
						<Space direction="horizontal">
							<Radio className="font-normal text-green-900"
							       value="Yes">Yes</Radio>
							<Radio className="font-normal text-amber-800"
							       value="No">No</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name="feedbackText"
					className="font-semibold text-gray-800"
					
					label="Message"
					rules={[{required: true, message: 'Your response is required'}]}
				>
					<TextArea
						rows={4}
						showCount
						className="font-normal text-gray-800 "
						placeholder={'Type in your message here'}
						maxLength={1000}/>
				</Form.Item>
				
				<Button className="bg-green-800 font-semibold text-xs md:text-sm text-gray-100"
				        type="primary" htmlType="submit">
					Submit
				</Button>
			</Form>
		</>
		}
	  </>
  );
};

export {SubmitContactUsGoogleForm};
