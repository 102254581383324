import {defineMessages} from 'react-intl';

const headerMessages = defineMessages({
  home: {
	id: 'header.home',
	defaultMessage: 'Home'
	
  },
  news: {
	id: 'header.news',
	defaultMessage: 'News'
  },
  about: {
	id: 'header.about',
	defaultMessage: 'About'
  },
  faq: {
	id: 'header.faq',
	defaultMessage: 'FAQs'
  },
  stations: {
	id: 'header.stations',
	defaultMessage: 'My Stations'
  },
  privacyPolicy: {
	id: 'header.privacyPolicy',
	defaultMessage: 'Privacy Policy'
  },
  stationLocateNews: {
	id: 'header.stationLocateNews',
	defaultMessage: 'Station Locate News'
  },
  stationLocateBlog: {
	id: 'header.stationLocateBlog',
	defaultMessage: 'Station Locate Blog'
  },
  stationLocatePressReleases: {
	id: 'header.stationLocatePressReleases',
	defaultMessage: 'Press Releases'
  },
  disclaimer: {
	id: 'header.disclaimer',
	defaultMessage: 'Disclaimer'
  },
  accountDeletionPage: {
	id: 'header.accountDeletionPage',
	defaultMessage: 'Account Deletion'
  },
  termsOfUse: {
	id: 'header.termsOfUse',
	defaultMessage: 'Terms of Use'
  },
  search: {
	id: 'header.search',
	defaultMessage: 'Search'
  },
  locations: {
	id: 'header.locations',
	defaultMessage: 'My Locations'
  },
  
  mobileDrawer: {
	id: 'header.mobileDrawer',
	defaultMessage: 'Open main menu'
  },
  notificationsTitle: {
	id: 'header.notificationsTitle',
	defaultMessage: 'View notifications'
  },
  profile: {
	id: 'header.loggedInUser',
	defaultMessage: 'Your Profile'
  },
  settings: {
	id: 'header.loggedInUserSettings',
	defaultMessage: 'settings'
  },
  logout: {
	id: 'header.loggedInUserLogout',
	defaultMessage: 'Log Out'
  }
});

export {headerMessages};
