import BHTechLogo from '../../../Images/BHTech_logo.png'
import LATimesLogo from '../../../Images/LATimesLogo.png'
import {PublicationSources} from "../../../utils/app";

type PublicationImageToUseProps = {
  src: PublicationSources;
}

const publicationImageToUse = ({src}: PublicationImageToUseProps) => {
  switch (src) {
	case'station-locate':
	  return BHTechLogo
	case 'la-times':
	  return LATimesLogo
	
	default:
	  return BHTechLogo
  }
  
}

export {publicationImageToUse};
