import { defineMessages } from 'react-intl';

const homeTabMessages = defineMessages({
  one: {
    id: 'homeTabMessages.one',
    defaultMessage: 'Welcome to Station Locate',
  },
  two: {
    id: 'homeTabMessages.two',
    defaultMessage: 'Fill up your battery with ease',
  },
  three: {
    id: 'homeTabMessages.three',
    defaultMessage: 'Taking the stress out of the search',
  },
});

export { homeTabMessages };
