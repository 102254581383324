import React from "react";
import {useIntl} from 'react-intl';
import {useNavigate} from "react-router-dom";
import ChevronDownIcon from '../../../Images/TriangleDownIcon.png'
import {footerMessages, headerMessages} from '../../../messages';
import {NewsAndBlog} from "../../../utils/header/NewsAndBlog";
import {LegalsAndPolicies} from "../../../utils/footer";

const HeaderTabsItemsDesktop = () => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const [states, setStates] = React.useState({
	showLegalsAndPolicies: false,
	showNews: false
	
  });
  
  const {showNews, showLegalsAndPolicies} = states
  const closeDropDowns = () => {
	setStates((e) => ({
	  ...e,
	  showLegalsAndPolicies: false,
	  showNews: false
	}));
  };
  
  const aboutButtonClick = () => {
	navigate('/about')
  }
  const homeButtonClick = () => {
	navigate('/')
  }
  const legalsButtonClick = (buttonClicked: string) => {
	
	navigate(buttonClicked)
	setStates((e) => ({
	  ...e,
	  showLegalsAndPolicies: !showLegalsAndPolicies
	}));
	
  };
  
  const commonButtonClasses = 'flex py-1  md:py-2 px-1 md:px-3  rounded-md text-xs md:text-sm md:font-medium  transform hover:scale-110 focus:outline-none'
  const highlighted = 'bg-amber-600  text-gray-900'
  const blurred = 'bg-gray-600  text-gray-300'
  
  return (
	  <div
		  className="md:ml-10 mr-2 flex items-center justify-center space-x-1 md:space-x-4"
		  onMouseLeave={closeDropDowns}
	  >
		<button
			className={
			  `${window.location.pathname === '/home' || window.location.pathname === '/' ? `${highlighted}`
				  : `${blurred}`}  ${commonButtonClasses} ml-2 md:ml-4`
			}
			onClick={homeButtonClick}
		>
		  {formatMessage(headerMessages.home)}
		</button>
		
		<div className="flex flex-row transform hover:scale-110  ">
		  <button
			  className={`${(window.location.pathname === '/in-the-news' || window.location.pathname
				  === '/press-releases' || window.location.pathname === '/blog') ? `${highlighted}`
				  : `${blurred}`} flex py-1 md:ml-10 md:py-2 px-1 md:px-3  rounded-md text-xs md:text-sm md:font-medium focus:outline-none`}
			  onClick={() => setStates((e) => ({
				...e,
				showNews: !showNews,
				showLegalsAndPolicies: false
			  }))}
		  >
			{formatMessage(headerMessages.news)}
		  </button>
		  <img
			  className={`  mt-2 md:mt-3 ml-0.5 h-2 md:h-3 w-2 md:w-5 mr-2 md:mr-0`}
			  src={ChevronDownIcon} alt="Logo"
			  onClick={() => setStates((e) => ({
				...e,
				showNews: !showNews,
				showLegalsAndPolicies: false
			  }))}
		  />
		  
		  {showNews && (
			  <NewsAndBlog buttonClicks={legalsButtonClick}/>
		  )}
		
		</div>
		<div>
		  <button
			  className={
				`${window.location.pathname === '/about' ? `${highlighted}`
					: `${blurred}`}  ${commonButtonClasses} md:mr-10 `
			  }
			  onClick={aboutButtonClick}
		  >
			{formatMessage(headerMessages.about)}
		  </button>
		
		</div>
		
		
		<div className="flex flex-row transform hover:scale-110  ">
		  <button
			  className={`${(window.location.pathname === '/terms-of-use' || window.location.pathname
				  === '/account-deletion' || window.location.pathname === '/privacy-policy'
				  || window.location.pathname === '/disclaimer') ? `${highlighted}`
				  : `${blurred}`} flex py-1  md:py-2 px-1 md:px-3  rounded-md text-xs md:text-sm md:font-medium focus:outline-none`}
			  onClick={() => setStates((e) => ({
				...e,
				showLegalsAndPolicies: !showLegalsAndPolicies,
				showNews: false
			  }))}
		  >
			{formatMessage(footerMessages.legals)}
		  </button>
		  <img
			  className={`  mt-2 md:mt-3 ml-0.5 h-2 md:h-3 w-2 md:w-5`}
			  src={ChevronDownIcon} alt="Logo"
			  onClick={() => setStates((e) => ({
				...e,
				showLegalsAndPolicies: !showLegalsAndPolicies,
				showNews: false
			  }))}/>
		  {showLegalsAndPolicies && (
			  <LegalsAndPolicies buttonClicks={legalsButtonClick} source={'header'}/>
		  )}
		</div>
	  </div>
  );
};
export {HeaderTabsItemsDesktop};
