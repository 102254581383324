import {NewsCategoryType} from "../../../utils/app";

type ExtractNewsPublisherProps = {
	categories: string[]
}


const extractNewsCategory = (props: ExtractNewsPublisherProps) => {
	const {categories} = props
	const initialPublisherPresetValues = {
		'charging-network-news': false,
		'legislation-news': false,
		'politics-news': false,
		'technology-news': false,
		'business-news': false
	}
	
	const categoriesPresent = new Map(Object.entries(initialPublisherPresetValues).map(([key, value]) => [key, value]))
	if (categories.includes('charging-network-news')) {
		categoriesPresent.set('charging-network-news', true)
	}
	if (categories.includes('legislation-news')) {
		categoriesPresent.set('legislation-news', true)
	}
	if (categories.includes('politics-news')) {
		categoriesPresent.set('politics-news', true)
	}
	if (categories.includes('technology-news')) {
		categoriesPresent.set('technology-news', true)
	}
	if (categories.includes('business-news')) {
		categoriesPresent.set('business-news', true)
	}
	
	if (categoriesPresent.get('charging-network-news')) {
		return 'Charging Network News' as NewsCategoryType
	}
	if (categoriesPresent.get('legislation-news')) {
		return 'Legislation News' as NewsCategoryType
	}
	if (categoriesPresent.get('politics-news')) {
		return 'Politics News' as NewsCategoryType
	}
	if (categoriesPresent.get('technology-news')) {
		return 'Technology News' as NewsCategoryType
	}
	if (categoriesPresent.get('business-news')) {
		return 'Business News' as NewsCategoryType
	}
	
	
}

export {extractNewsCategory}
