import {useIntl} from 'react-intl';
import {footerMessages} from '../../messages';

type SocialMediaProps = {
  buttonClicks: (buttonClicked: string) => void;
};
const SocialMedia = ({
					   buttonClicks
					 }: SocialMediaProps) => {
  const {formatMessage} = useIntl();
  
  return (
	  <div
		  className=" absolute  -mt-[95px] md:-mt-[120px]  w-20  md:w-24 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
		  role="menu"
		  aria-orientation="vertical"
		  aria-labelledby="user-menu-button"
		  tabIndex={-1}
		  onMouseLeave={() => buttonClicks('socialMedia')}
	  >
		<a
			href="https://www.facebook.com/BackHomeTechnologies/"
			className="block px-2 py-1  text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			role="menuitem"
			target="_blank"
			rel="noopener noreferrer"
			tabIndex={-1}
			id="user-menu-item-0"
		>
		  {formatMessage(footerMessages.facebook)}
		</a>
		<a
			href="https://twitter.com/BackHome_Tech"
			className="block px-2 py-1  text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			role="menuitem"
			tabIndex={-1}
			target="_blank"
			rel="noopener noreferrer"
			id="user-menu-item-1"
		>
		  {formatMessage(footerMessages.twitter)}
		</a>
		<a
			href="https://www.instagram.com/backhome_technologies/"
			className="block px-2 py-1  text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			role="menuitem"
			target="_blank"
			rel="noopener noreferrer"
			tabIndex={-1}
			id="user-menu-item-2"
		>
		  {formatMessage(footerMessages.instagram)}
		</a>
	  </div>
  );
};

export {SocialMedia};
