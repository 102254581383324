import { defineMessages } from 'react-intl';

const footerMessages = defineMessages({
  company: {
    id: 'footer.company',
    defaultMessage: 'BackHome',
  },
  follow: {
    id: 'footer.follow',
    defaultMessage: 'Follow Us',
  },
  support: {
    id: 'footer.support',
    defaultMessage: 'Help and Support',
  },
  legals: {
    id: 'footer.legals',
    defaultMessage: 'Legals',
  },
  privacyPolicy: {
    id: 'footer.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  tos: {
    id: 'footer.tos',
    defaultMessage: 'Terms of Service',
  },
  haveAccountQuestion: {
    id: 'footer.haveAccountQuestion',
    defaultMessage: 'Have an account?',
  },
  facebook: {
    id: 'footer.facebook',
    defaultMessage: 'Facebook',
  },
  twitter: {
    id: 'footer.twitter',
    defaultMessage: 'Twitter',
  },
  instagram: {
    id: 'footer.instagram',
    defaultMessage: 'Instagram',
  },
  about: {
    id: 'footer.about',
    defaultMessage: 'About us',
  },
  news: {
    id: 'footer.news',
    defaultMessage: 'In the news',
  },
  contactSupport: {
    id: 'footer.contactSupport',
    defaultMessage: 'Contact Support',
  },
  faq: {
    id: 'footer.faq',
    defaultMessage: 'FAQs',
  },
});

export { footerMessages };
