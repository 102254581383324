import {configureStore} from '@reduxjs/toolkit';

import {displayReducer,} from './redux/reducers';

import {DisplayReducerType} from './utils/app';

export const coreStore = configureStore({
    reducer: {
        allDisplayInfo: displayReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({serializableCheck: false}),
});

export const dispatch = coreStore.dispatch;
const subscribeToStore = coreStore.subscribe;

const storeStates = () => {
    return {
        allDisplayInfoStates: coreStore.getState()
            .allDisplayInfo as DisplayReducerType['allDisplayInfo'],
    };
};
subscribeToStore(storeStates);

export const allCurrentStoreStates = () => storeStates();
