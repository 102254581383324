import {useIntl}         from 'react-intl';
import {useLocation}     from 'react-router-dom';
import Layout            from '../components/Layout';
import {homeTabMessages} from '../messages';
import {
    About,
    Home,
    InTheNews,
    StationLocateBlog,
    StationLocateDisclaimer,
    StationLocatePressReleases,
    StationLocatePrivacyPolicy,
    StationLocateTermsOfService
}                        from '../pages';
import {HeaderText}      from '../utils/header';

const MainContainer = () => {
  const {formatMessage} = useIntl();
  const randomPageMessage = (messageGroup: any) => {
	const keys = Object.keys(messageGroup) as [];
	return messageGroup[keys[Math.floor(Math.random() * keys.length)]];
  };
  const initialHomeMessage = randomPageMessage(homeTabMessages);
  const {pathname} = useLocation()
  
  return (
	  <>
		{(pathname === '/home' || pathname === '/') && <Layout
            headerChildren={HeaderText({
			  headerText: formatMessage(initialHomeMessage)
			})}
        >
            <Home/>
        </Layout>
		}
		{(pathname === '/in-the-news') && <Layout
            headerChildren={HeaderText({
			  headerText: formatMessage(initialHomeMessage)
			})}
        >
            <InTheNews/>
        </Layout>
		}
		
		{(pathname === '/blog') && <Layout
            headerChildren={HeaderText({
			  headerText: formatMessage(initialHomeMessage)
			})}
        >
            <StationLocateBlog/>
        </Layout>
		}
		
		{(pathname === '/press-releases') && <Layout
            headerChildren={HeaderText({
			  headerText: formatMessage(initialHomeMessage)
			})}
        >
            <StationLocatePressReleases/>
        </Layout>
		}
		{(pathname === '/about') && <Layout
            headerChildren={HeaderText({
			  headerText: formatMessage(initialHomeMessage)
			})}
        >
            <About/>
        </Layout>
		}
		
		
		{pathname === '/privacy-policy' && <Layout
            headerChildren={HeaderText({
			  headerText: formatMessage(initialHomeMessage)
			})}
        >
            <StationLocatePrivacyPolicy/>
        </Layout>
		}
		
		{pathname === '/disclaimer' && <Layout
            headerChildren={HeaderText({
			  headerText: formatMessage(initialHomeMessage)
			})}
        >
            <StationLocateDisclaimer/>
        </Layout>
		}
		
		
		{pathname === '/terms-of-use' &&
            <Layout
                headerChildren={HeaderText({
				  headerText: formatMessage(initialHomeMessage)
				})}
            >
                <StationLocateTermsOfService/>
            </Layout>
		}
	  
	  </>
  );
};

export default MainContainer
