import TermsOfUse from "./TermsOfUse";

const StationLocateTermsOfService = () => {
  return <div>
	<div className="max-w-7xl mx-auto py-6 min-h-[109vh]   sm:px-6 lg:px-8">
	  <TermsOfUse/>
	</div>
  </div>
}

export {StationLocateTermsOfService}
