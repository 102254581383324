import React, { useEffect } from "react";
export default function TermsOfUse() {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://app.termly.io/embed-policy.min.js";
		script.async = true;
		document.body.appendChild(script)
	}, []);
	
	return (
		<div
			name="termly-embed"
			data-id="991243bc-c591-4e51-84a1-6acf4be88643"
			datatype="iframe"
		></div>
	);
}
