import StationAppScreenshots from "../../Images/StationAppScreenshots.png";
import SearchIcon from "../../Images/SearchIcon.png";
import InfoIcon from "../../Images/InfoIcon.png";
import MemberIcon from "../../Images/MemberIcon.png";
import UserProfile from "../../Images/UserProfile.png";
import MapViewIcon from "../../Images/MapViewIcon.png";
import GreenSuccessIcon from "../../Images/GreenSuccessIcon.png";

import React from "react";

const StationLocateAppDownloadContainer = () => {
  
  const listIcons = 'h-3 md:h-6 w-3 md:w-6  mx-0.5 md:mx-2 mr-1'
  const listContainer = 'flex rounded md:rounded-xl   flex-row mt-1 px-0.5 py-1 bg-gradient-to-r '
  return (
	  <div className='flex flex-col justify-center items-center'>
		<p className="text-xl md:text-4xl pt-4 md:mb-10 ml-4 md:ml-10 antialiased font-semibold text-green-900">
		  Download Station Locate App
		</p>
		<div className='flex flex-col md:flex-row gap-6'>
		  <div>
			<img className="h-100 w-100 mr-2 mt-3 " src={StationAppScreenshots} alt="Logo"/>
		  </div>
		  <div>
			<div className="flex flex-row sm:px-0">
			  <div className="ml-2 md:ml-6 mr-1">
				<ul className="md:mt-2 p-3 text-xs md:text-lg text-white ">
				  <li className={`${listContainer} items-center  from-green-800 to-indigo-400 `}>
					<img className={`${listIcons}`} src={SearchIcon} alt="Logo"/>
					<p>Find stations nearby, or a different address anywhere in North
					  America</p>
				  </li>
				  <li className={`${listContainer} items-center  from-sky-400 to-indigo-400`}>
					<img className={`${listIcons}`} src={MapViewIcon} alt="bullet-poin"/>
					<p>View results on a map, or as a list, your choice!</p>
				  </li>
				  <li className={`${listContainer} items-center from-violet-400 to-fuchsia-400`}>
					<img className={`${listIcons}`}
						 src={InfoIcon}
						 alt="Logo"/>
					<p>View detailed information about a chosen station, call the location or
					  provider for more information</p>
				  </li>
				  <li className={`${listContainer} from-green-800 to-fuchsia-400`}>
					<img className={`${listIcons} mt-3`} src={MemberIcon} alt="bullet-point"/>
					<div>
					  <p>Become a member for more features like </p>
					  <ul className="ml-5 md:text-sm ">
						<li className=" flex flex-row mt-1 items-center ">
						  <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="bullet-point"/>
						  Save locations (home, office, etc) for quick
						  searches
						</li>
						<li className=" flex flex-row mt-1 items-center ">
						  <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="bullet-point"/>
						  See a station you like? Add it to your favorites!
						</li>
						<li className=" flex flex-row mt-1 items-center ">
						  <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="bullet-point"/>
						  Add your car**
						</li>
						<li className=" flex flex-row mt-1 items-center ">
						  <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="bullet-point"/>
						  Get helped from highly ranked members
						</li>
						<li className=" flex flex-row mt-1 items-center ">
						  <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="Logo"/>
						  User reviews
						</li>
						<li className=" flex flex-row mt-1 items-center ">
						  <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="Logo"/>
						  Being part of a pioneer community
						</li>
					  </ul>
					</div>
				  </li>
				
				</ul>
				<div
					className=" flex  md:mt-2  items-center justify-center  flex-row ">
				  <a href="https://apps.apple.com/us/app/station-locate/id6469472521?itsct=apps_box_badge&amp;itscg=30200"
					 target='_blank'
					 rel="noreferrer"
				  
				  >
					<img
						className='rounded-xl h-12 md:h-24 w-32 lg:w-64 md:w-64'
						src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1697673600"
						alt="Download on the App Store"/></a>
				  <a href='https://play.google.com/store/apps/details?id=com.stationlocatemobilev10&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
					 target='_blank'
					 rel="noreferrer"
				  >
					<img
						className='rounded-xl h-16 lg:h-32 md:h-20 w-36 md:w-72'
						src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
						alt='Get it on Google Play'/></a>
				
				</div>
			  </div>
			  <div>
				<img className="h-[32vh] w-[32vw] hidden md:block mr-1" src={UserProfile} alt="Logo"/>
			  </div>
			</div>
		  
		  
		  </div>
		</div>
	  </div>
  )
}

export {StationLocateAppDownloadContainer};
