import React from "react";
import {useIntl} from 'react-intl';
import {headerMessages} from '../../messages';

const {privacyPolicy, termsOfUse, disclaimer} = headerMessages

type LegalsAndPoliciesProps = {
  buttonClicks: (buttonClicked: string) => void;
  source: string;
};

const LegalsAndPolicies: React.FunctionComponent<LegalsAndPoliciesProps> = ({
																			  buttonClicks, source
																			}: LegalsAndPoliciesProps) => {
  const {formatMessage} = useIntl();
  const legalDropdownPosition = source === 'footer' ? 'origin-top-right flex flex-col -'
	  : 'origin-bottom-left mt-[25px] md:mt-[40px] -ml-[30px] md:ml-0';
  return (
	  <div
		  className={`${legalDropdownPosition}  absolute  ml-1  pl-[0.3rem] ${source !== 'footer' && 'w-20 md:w-28'}   -mt-[85px] md:-mt-[110px]  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
		  role="menu"
		  aria-orientation="vertical"
		  aria-labelledby="legals-menu-button"
		  tabIndex={-1}
	  >
		
		<button
			className="   pt-1 md:pt-2  text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			type="button"
			onClick={() => {
			  buttonClicks('/privacy-policy');
			}}
		>
		  {formatMessage(privacyPolicy)}
		</button>
		
		<button
			className="pt-1 md:pt-2   text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			type="button"
			onClick={() => {
			  buttonClicks('/terms-of-use');
			}}
		>
		  {formatMessage(termsOfUse)}
		</button>
		
		
		<button
			className="pt-1 md:pt-2   text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			type="button"
			onClick={() => {
			  buttonClicks('/disclaimer');
			}}
		>
		  {formatMessage(disclaimer)}
		</button>
	  </div>
  );
};

export {LegalsAndPolicies};
