import React from "react";
import {useIntl} from 'react-intl';
import {footerMessages} from '../../messages';

type HelpAndSupportProps = {
  buttonClicks: (buttonClicked: string) => void;
};
const HelpAndSupport: React.FunctionComponent<HelpAndSupportProps> = ({
																		buttonClicks
																	  }: HelpAndSupportProps) => {
  const {formatMessage} = useIntl();
  
  return (
	  <div
		  className="absolute -indent-px-[10vw] -mt-[55px] md:-mt-[60px] w-28   md:w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
		  role="menu"
		  aria-orientation="vertical"
		  aria-labelledby="help-menu-button"
		  tabIndex={-1}
	  >
		<a
			href="mailto:support@backhometechnologies.com?subject=Help%20and%20Support&body=Please%20type%20a%20question%20below"
			className="px-2 py-2  text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
			tabIndex={-1}
			// target="_blank"
			rel="noopener noreferrer"
			id="user-menu-item-0"
		>
		  {formatMessage(footerMessages.contactSupport)}
		</a>
	  </div>
  );
};

export {HelpAndSupport};
