import React from "react";
import {NewsItemType, titilliezNameOrPublisher} from "../../utils/app";

type NewsCardProps = {
	newsItem: NewsItemType;
}

// https://feedly.com/i/top/ev-blogs
// https://follow.it/electric-cars-report?pub
// https://rss.feedspot.com/ev_charging_rss_feeds/?_src=rss_directory_e
// https://javascript.plainenglish.io/embedding-google-forms-in-react-apps-f0a89a82d79a
// https://www.greencarreports.com/rss-feeds
const NewsCard = (x: NewsCardProps) => {
	const {newsItem} = x;
	const {publisherImage, category, title, authorName, description, sourceUrl, publisherName} = newsItem;
	
	return (
		<div className="w-44 md:w-full drop-shadow-lg shadow-2xl bg-gray-200 rounded-2xl ">
			<div className="flex justify-center">
				<img className=" h-16 md:h-16 mt-1 md:mt-3  rounded-xl w-28 md:w-32" src={publisherImage} alt="Logo"/>
			</div>
			<div className="mx-3 mb-2 ">
				<div className="flex flex-col md:flex-row  py-1 md:py-3">
					<p className="mr-1 text-gray-500 text-2xs md:text-2xs"><b>Published
						At</b>: {titilliezNameOrPublisher({str: publisherName})}</p>
					<p className="text-gray-500 text-2xs md:text-2xs">{category}</p>
					<p className="md:ml-2 text-gray-500 text-2xs md:text-2xs">
						<b>Author</b>: {titilliezNameOrPublisher({str: authorName})}</p>
				</div>
				<p className="text-xs md:text-sm font-serif pb-2 font-semibold text-gray-700">{title}</p>
				
				<p className=" text-sm md:text-base font-sans text-gray-900 line-clamp-5 ">{description}</p>
				
				<a href={sourceUrl} target="_blank" rel="noreferrer" className="text-blue-400 font-semibold">Read
					more</a>
			</div>
		
		</div>
	);
	
}

export {NewsCard}
