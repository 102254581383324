import React from "react";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {footerMessages} from "../messages";
import {EmptyProps} from "../utils/app";
import {Company, HelpAndSupport, LegalsAndPolicies, SocialMedia} from "../utils/footer";

const Footer: React.FunctionComponent<EmptyProps> = () => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  
  const [states, setStates] = React.useState({
	showCompanyInfo: false,
	showSocialMedia: false,
	showLegalsAndPolicies: false,
	showHelpAndSupport: false
  });
  
  const buttonClicks = (buttonClicked: string) => {
	if (buttonClicked === "company") {
	  setStates((e) => ({
		...e,
		showCompanyInfo: !states.showCompanyInfo,
		showSocialMedia: false,
		showHelpAndSupport: false,
		showLegalsAndPolicies: false
	  }));
	}
	
	if (buttonClicked === "socialMedia") {
	  setStates((e) => ({
		...e,
		showCompanyInfo: false,
		showSocialMedia: !states.showSocialMedia,
		showHelpAndSupport: false,
		showLegalsAndPolicies: false
	  }));
	}
	
	if (buttonClicked === "HelpAndSupport") {
	  setStates((e) => ({
		...e,
		showCompanyInfo: false,
		showSocialMedia: false,
		showHelpAndSupport: !states.showHelpAndSupport,
		showLegalsAndPolicies: false
	  }));
	}
	
	if (buttonClicked === "shutItAllDown") {
	  setStates((e) => ({
		...e,
		showCompanyInfo: false,
		showSocialMedia: false,
		showHelpAndSupport: false,
		showLegalsAndPolicies: false
	  }));
	}
	
	if (buttonClicked === "LegalsAndPolicies") {
	  setStates((e) => ({
		...e,
		showCompanyInfo: false,
		showSocialMedia: false,
		showHelpAndSupport: false,
		showLegalsAndPolicies: !states.showLegalsAndPolicies
	  }));
	}
  };
  
  const legalsButtonClick = (buttonClicked: string) => {
	navigate(buttonClicked)
	setStates((e) => ({
	  ...e,
	  showLegalsAndPolicies: !states.showLegalsAndPolicies
	}));
  };
  
  const companyButtonClicks = (buttonClicked: string) => {
	navigate(buttonClicked)
	setStates((e) => ({
	  ...e,
	  showCompanyInfo: !states.showCompanyInfo
	}));
	
  };
  
  const helpAndSupportButtonClicks = (buttonClicked: string) => {
	navigate(buttonClicked)
	setStates((e) => ({
	  ...e,
	  showHelpAndSupport: !states.showHelpAndSupport
	}));
	
  };
  return (
	  <div className="flex  flex-col w-auto h-auto  sticky  bottom-0 z-20">
		<nav className="flex bg-[#065F46]">
		  <div
			  className="flex  mx-auto px-4 sm:px-6 space-x-4 items-center md:flex lg:px-8 h-10"
			  onMouseLeave={() => buttonClicks("shutItAllDown")}
		  >
			<div>
			  <button
				  className="text-gray-300 hover:bg-gray-700 hover:text-white px-1 md:px-3 py-1 md:py-2 rounded-md text-xs md:text-sm font-medium focus:outline-none"
				  onClick={() => buttonClicks("company")}
			  >
				{formatMessage(footerMessages.company)}
			  </button>
			  {states.showCompanyInfo && <Company buttonClicks={companyButtonClicks}/>}
			</div>
			
			<div>
			  <button
				  className="text-gray-300 hover:bg-gray-700 hover:text-white px-1 md:px-3 py-1 md:py-2 rounded-md text-xs md:text-sm font-medium focus:outline-none"
				  onClick={() => buttonClicks("socialMedia")}
			  >
				{formatMessage(footerMessages.follow)}
			  </button>
			  
			  {states.showSocialMedia && (
				  <SocialMedia buttonClicks={buttonClicks}/>
			  )}
			</div>
			
			<div>
			  <button
				  className="text-gray-300 hover:bg-gray-700 hover:text-white px-1 md:px-3 py-1 md:py-2 rounded-md text-xs md:text-sm font-medium focus:outline-none"
				  onClick={() => buttonClicks("HelpAndSupport")}
			  >
				{formatMessage(footerMessages.support)}
			  </button>
			  
			  {states.showHelpAndSupport && (
				  <HelpAndSupport buttonClicks={helpAndSupportButtonClicks}/>
			  )}
			</div>
			
			<div>
			  <button
				  className="text-gray-300 hover:bg-gray-700 hover:text-white px-1 md:px-3 py-1 md:py-2 rounded-md text-xs md:text-sm font-medium focus:outline-none"
				  onClick={() => buttonClicks("LegalsAndPolicies")}
			  >
				{formatMessage(footerMessages.legals)}
			  </button>
			  
			  {states.showLegalsAndPolicies && (
				  <LegalsAndPolicies buttonClicks={legalsButtonClick} source={'footer'}/>
			  )}
			</div>
		  </div>
		</nav>
	  </div>
  );
};

export default Footer;
