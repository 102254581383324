import {ReactNode, useEffect, useState} from "react";
import {BlogCard} from "../pageComponents/news";
import {BlogArticleType} from "../utils/app";

type NewsBodyProps = {
  children?: ReactNode;
};

const StationLocateBlog = ({children}: NewsBodyProps) => {
  const [mediumBlog, setMediumBlog] = useState({
	
	blogItems: [] as BlogArticleType[],
	isLoading: true,
	error: null
  });
  
  const {blogItems, isLoading} = mediumBlog;
  useEffect(() => {
	fetch('https://us-central1-stationlocate-prod.cloudfunctions.net/slnev1?blogArticles').then(async res => {
		  const blogArticles = await res.json();
		  setMediumBlog(e => ({...e, blogItems: blogArticles, isLoading: false}));
		}
	).catch(err => {
	  setMediumBlog(e => ({
		...e,
		error: err.message
	  }));
	});
  }, [])
  
  const hasBlog = () => {
	if (!!blogItems.length) {
	  return <div className="grid gap-2 md:gap-3 grid-cols-2 md:grid-cols-3 ">
		
		{blogItems.map((post: any, index: number) => <BlogCard key={index} blogPost={post}/>)}
	  </div>
	} else {
	  return <div className="grid gap-2 md:gap-3 grid-cols-2 md:grid-cols-3 ">
		<div className="col-span-2">
		  <div className="text-center">
			<p className="text-gray-500 text-sm">
			  No articles found
			</p>
		  </div>
		</div>
	  </div>
	}
  }
  
  return (
	  <div>
		<main>
		  {children}
		  <div className="max-w-7xl mx-auto py-6 min-h-[109vh] max-h-[4000vh]  sm:px-6 lg:px-8">
			<div className="px-4 py-6 sm:px-0">
			  <div className="px-4 sm:px-0">
				<h3 className="flex justify-center text-green-900 tracking-widest font-sans antialiased  text-4xl font-extrabold md:mb-8">Welcome
				  To Station
				  Locate
				  Blogs</h3>
				{isLoading ? <p>Loading...</p> : hasBlog()}
			  </div>
			
			</div>
		  </div>
		</main>
	  </div>
  );
};

export {StationLocateBlog};
