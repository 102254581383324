import React from "react";

const MissionCard = () => {
  
  return (
	  
	  <div className="flex md:mt-5 mb-10 flex-col justify-center items-center ">
		<h1 className=" text-lg md:mb-5 md:text-5xl font-semibold text-green-900  ">Our
		  Mission</h1>
		<p className="text-gray-600 text-3xl ">
		  To facilitate mass adoption of <b className="text-gray-700">sustainable</b> transportation systems
		</p>
	  </div>
  )
}

export {MissionCard}
