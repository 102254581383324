import React from "react";
import BackHomeLogo512 from "../../Images/BHLogo_HText.png";
import {HeaderTabsItemsDesktop} from "./headerItems";

const Header = () => {
  
  return (
	  <div className="flex flex-col sticky  top-0 z-10">
		<nav className="bg-[#065F46]">
		  <div className=" mx-1 md:mx-auto  md:px-4  lg:px-8">
			<div className="flex  justify-center h-20 md:mr-2">
			  <div className="flex ">
				<div className="flex rounded-lg items-center">
				  
				  <img
					  className="  md:mr-2 md:h-full  flex  align-center"
					  src={BackHomeLogo512}
					  alt="Station Locate Logo"
				  />
				
				</div>
				<HeaderTabsItemsDesktop
				/>
			  </div>
			
			</div>
		  </div>
		</nav>
	  </div>
  );
};

export default Header;
